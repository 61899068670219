import clsx from "clsx";
import * as Icon from "components/Icon";
import { everIdProp } from "EverAttribute/EverId";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, {
    CSSProperties,
    ForwardedRef,
    forwardRef,
    HTMLAttributes,
    ReactNode,
    UIEventHandler,
    useRef,
} from "react";
import "./Text.scss";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverIdProp, FFC } from "util/type";
import { isExternalUrl } from "util/url";

export enum TextVariant {
    SMALL = "small",
    SMALL_SEMIBOLD = "small semibold",
    SMALL_BOLD = "small bold",
    SMALL_ITALIC = "small italic",
    SMALL_NUMBER = "small number",
    DEFAULT = "default",
    SEMIBOLD = "semibold",
    BOLD = "bold",
    ITALIC = "italic",
    NUMBER = "number",
    OVERLINE = "overline",
}

const TEXT_VARIANT_MAP = {
    [TextVariant.SMALL]: "bb-text--small",
    [TextVariant.SMALL_SEMIBOLD]: "bb-text--small-semibold",
    [TextVariant.SMALL_BOLD]: "bb-text--small-bold",
    [TextVariant.SMALL_ITALIC]: "bb-text--small-italic",
    [TextVariant.SMALL_NUMBER]: "bb-text--small-number",
    [TextVariant.DEFAULT]: "bb-text",
    [TextVariant.SEMIBOLD]: "bb-text--semibold",
    [TextVariant.BOLD]: "bb-text--bold",
    [TextVariant.ITALIC]: "bb-text--italic",
    [TextVariant.NUMBER]: "bb-text--number",
    [TextVariant.OVERLINE]: "bb-text--overline",
};

type TextVariantName =
    | "Small"
    | "SmallSemibold"
    | "SmallBold"
    | "SmallItalic"
    | "SmallNumber"
    | "Default"
    | "Semibold"
    | "Bold"
    | "Italic"
    | "Number"
    | "Overline";

type TextVariantNameMap<T> = { [N in TextVariantName]: T };

const TEXT_VARIANT_NAME_MAP: TextVariantNameMap<TextVariant> = {
    Small: TextVariant.SMALL,
    SmallSemibold: TextVariant.SMALL_SEMIBOLD,
    SmallBold: TextVariant.SMALL_BOLD,
    SmallItalic: TextVariant.SMALL_ITALIC,
    SmallNumber: TextVariant.SMALL_NUMBER,
    Default: TextVariant.DEFAULT,
    Semibold: TextVariant.SEMIBOLD,
    Bold: TextVariant.BOLD,
    Italic: TextVariant.ITALIC,
    Number: TextVariant.NUMBER,
    Overline: TextVariant.OVERLINE,
};

type TextElement = "p" | "span";

export interface TextProps<E extends HTMLSpanElement | HTMLParagraphElement>
    extends HTMLAttributes<E>,
        EverIdProp {
    /**
     * The inner body of the text element.
     */
    children: ReactNode;
    /**
     * The type of element, either {@code p} or {@code span}, to use.
     */
    element: TextElement;
    /**
     * The variant of text to use.
     * Defaults to {@link TextVariant.DEFAULT}.
     */
    variant?: TextVariant;
    /**
     * Whether the line-height should be set to the font size. Defaults to false.
     *
     * This option is useful when the parent element is shorter than the default line-height,
     * as the text may not be vertically centered within the parent otherwise.
     */
    matchLineHeightToSize?: boolean;
}

export type TextElementProps<E extends HTMLSpanElement | HTMLParagraphElement> = Omit<
    TextProps<E>,
    "element"
>;

type TextVariantProps<
    E extends HTMLSpanElement | HTMLParagraphElement,
    P extends TextProps<E> | TextElementProps<E>,
> = Omit<P, "variant">;

type TextFC<
    E extends HTMLSpanElement | HTMLParagraphElement,
    P extends TextProps<E> | TextElementProps<E>,
> = FFC<E, P> & TextVariantNameMap<FFC<E, TextVariantProps<E, P>>>;

/**
 * An element for body text, labels & captions.
 *
 * These styles are also available via Sass classes and mixins.
 */
const Text: FFC<
    HTMLSpanElement | HTMLParagraphElement,
    TextProps<HTMLSpanElement | HTMLParagraphElement>
> = forwardRef<
    HTMLSpanElement | HTMLParagraphElement,
    TextProps<HTMLSpanElement | HTMLParagraphElement>
>(
    (
        {
            variant = TextVariant.DEFAULT,
            element = "span",
            className,
            everId,
            children,
            matchLineHeightToSize = false,
            ...props
        },
        ref,
    ) => {
        return React.createElement(
            element,
            {
                className: clsx("bb-text", TEXT_VARIANT_MAP[variant], className, {
                    "bb-text--font-size-line-height": matchLineHeightToSize,
                }),
                ref,
                ...everIdProp(everId),
                ...props,
            },
            children,
        );
    },
);
Text.displayName = "Text";

export const Paragraph: TextFC<
    HTMLParagraphElement,
    TextElementProps<HTMLParagraphElement>
> = forwardRef<HTMLParagraphElement, Omit<TextProps<HTMLParagraphElement>, "element">>(
    (props, ref) => <Text {...props} element={"p"} ref={ref} />,
) as TextFC<HTMLParagraphElement, TextElementProps<HTMLParagraphElement>>;
Paragraph.displayName = "Paragraph";

export const Span: TextFC<HTMLSpanElement, TextElementProps<HTMLSpanElement>> = forwardRef<
    HTMLSpanElement,
    Omit<TextProps<HTMLSpanElement>, "element">
>((props, ref) => <Text {...props} element={"span"} ref={ref} />) as TextFC<
    HTMLSpanElement,
    TextElementProps<HTMLSpanElement>
>;
Span.displayName = "Span";

[Span, Paragraph].forEach((Component) => {
    (Object.keys(TEXT_VARIANT_NAME_MAP) as TextVariantName[]).forEach((variant) => {
        Component[variant] = forwardRef((props, ref) => {
            return (
                <Component
                    {...props}
                    variant={TEXT_VARIANT_NAME_MAP[variant]}
                    // Cast necessary because Paragraph's ref is of a different type
                    ref={ref as ForwardedRef<never>}
                />
            );
        });
        Component[variant].displayName = `${Component.displayName}.${variant}`;
    });
});

export interface LinkProps extends EverIdProp {
    /**
     * The inner text of the link.
     */
    children: ReactNode;
    /**
     * An optional class name to add to the link.
     */
    className?: string;
    /**
     * Custom inline styles to add to the link.
     */
    style?: CSSProperties;
    /**
     * The URL the link should direct the user to. If the link is external (i.e. not local to
     * app.everlaw.x), an icon will be added to the link automatically to indicate.
     */
    href?: string;
    /**
     * Whether the link should open in a new tab.
     *
     * Defaults to false.
     */
    newTab?: boolean;
    /**
     * A callback that is invoked when the link is clicked.
     */
    onClick?: UIEventHandler<HTMLAnchorElement>;
    /**
     * The variant of text to use.
     * Defaults to {@link TextVariant.DEFAULT}.
     */
    variant?: TextVariant;
}

type LinkVariantProps = Omit<LinkProps, "variant">;

type LinkFC = FFC<HTMLAnchorElement, LinkProps>
    & TextVariantNameMap<FFC<HTMLAnchorElement, LinkVariantProps>>;

function isLargeLink(variant: TextVariant): boolean {
    return (
        variant === TextVariant.DEFAULT
        || variant === TextVariant.NUMBER
        || variant === TextVariant.BOLD
        || variant === TextVariant.SEMIBOLD
    );
}

/**
 * A simple link component. Will automatically add an icon to external URLs.
 *
 * Allows forced opening in new tabs, and supports two sizes.
 */
export const Link: LinkFC = forwardRef<HTMLAnchorElement, LinkProps>(
    (
        {
            variant = TextVariant.DEFAULT,
            everId,
            className: classNameProp,
            style,
            href,
            newTab,
            onClick,
            children,
        },
        externalRef,
    ) => {
        const isLarge = isLargeLink(variant);
        const isRealLink = !!href;
        const isExternal = isRealLink && isExternalUrl(href);
        const className = clsx(
            "bb-link",
            `bb-link--${isLarge ? "large" : "small"}`,
            "bb-text",
            TEXT_VARIANT_MAP[variant],
            classNameProp,
        );
        const internalRef = useRef<HTMLAnchorElement>(null);
        const ref = useCombinedRef(internalRef, externalRef);
        return (
            <a
                href={href}
                rel={isExternal ? "noopener noreferrer" : ""}
                target={newTab ? "_blank" : "_self"}
                className={className}
                style={style}
                onClick={onClick}
                onKeyDown={(event) => {
                    if (isRealLink) {
                        return;
                    }
                    // Normally, links trigger when focused and you press Enter. However, if a link
                    // doesn't have an href prop, the Enter key doesn't trigger the onClick.
                    // This fixes that.
                    if (event.key === "Enter") {
                        internalRef.current?.click();
                    }
                }}
                ref={ref}
                // For links that don't have hrefs, you need tabIndex=0 to make the link tabbable
                tabIndex={isRealLink ? undefined : 0}
                {...everIdProp(everId)}
            >
                {children}
                {isExternal && (
                    <Icon.ArrowUpRight
                        size={isLarge ? 20 : 16}
                        color={ColorTokens.TEXT_LINK}
                        className={"bb-link__icon"}
                        aria-hidden={true}
                    />
                )}
            </a>
        );
    },
) as LinkFC;
Link.displayName = "Link";

(Object.keys(TEXT_VARIANT_NAME_MAP) as TextVariantName[]).forEach((variant) => {
    Link[variant] = forwardRef((props, ref) => {
        return <Link {...props} variant={TEXT_VARIANT_NAME_MAP[variant]} ref={ref} />;
    });
    Link[variant].displayName = `Link.${variant}`;
});
