import clsx from "clsx";
import * as Icon from "components/Icon";
import { IconProps } from "components/Icon/IconProps";
import { PopoverMenu, PopoverMenuPlacement } from "components/Menu/PopoverMenu";
import { SideNavVariant, VariantContext } from "components/SideNavigation/VariantContext";
import { Expandable } from "components/util/Expandable/Expandable";
import { everIdProp } from "EverAttribute/EverId";
import { useBrandedCallback } from "hooks/useBranded";
import { useButtonRole } from "hooks/useButtonRole";
import { useCssSelectorFilter } from "hooks/useCssSelectorFilter";
import { useFilteredEventListener } from "hooks/useFilteredEventListener";
import React, {
    FC,
    forwardRef,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useId,
    useRef,
    useState,
} from "react";
import { TooltipPlacement, useEllipsisTooltip } from "components/Tooltip";
import { EverColor } from "tokens/typescript/EverColor";
import { EverIdProp, FFC } from "util/type";
import { wrap } from "core";
import { useCombinedRef } from "hooks/useCombinedRef";
import { SMALL_SIZE } from "tokens/typescript/IconTokens";

export enum ItemType {
    SUB = "sub",
    STANDARD = "standard",
}

/**
 * Props that are common across all types of navigation items.
 * (In alphabetical order.)
 */
export interface CommonItemProps extends EverIdProp {
    /**
     * Is the navigation item currently selected.
     */
    active: boolean;
    /**
     * Whether the navigation item is in its collapsed form.
     */
    collapsed?: boolean;
    /**
     * Optional parameter to set the navigation item to disabled state.
     */
    disabled?: boolean;
    /**
     * Optional icon to the left of the navigation item label. This icon should have a size of 20px.
     */
    icon?: ReactElement<IconProps>;
    /**
     * Text label of the navigation item.
     */
    label: ReactNode;
    /**
     * The action taken when the navigation item is selected. Note that items do not change their
     * active state (`active`) internally, so this function should probably update `active`
     * via a hook as done in {@link useSideNavigationBar}.
     */
    onClick?: EventListener;
    /**
     * Contains elements like a right icon, popover menu, user badge, or expand/collapse sub-items
     * icon.
     */
    rightElements?: ReactNode;
}

/**
 * These props are only used internally by Bluebook.
 */
export interface BaseItemProps extends Omit<CommonItemProps, "onClick"> {
    /**
     * Color of the icons in the item.
     */
    iconColor?: EverColor;
    /**
     * Style classes that should be applied to the item.
     */
    itemClasses: string;
    /**
     * Complex navigation items can have a second line of content in this optional
     * parameter.
     */
    extraContent?: ReactNode;
    /**
     * Whether the item is a sub-item.
     */
    isSub?: boolean;
    /**
     * The sub-items of a parent item. Passed in by parent items only.
     */
    subItems?: ReactNode;
    /**
     * The divider below an item. Passed in by complex items only.
     */
    divider?: boolean;
    /**
     * Collapsed version of the item. Passed in by standard and parent items only since complex
     * items do not exist in collapsible side navigations.
     */
    collapsedItem?: ReactElement | undefined | false;
}

/**
 * Based on side nav variant and active state, return the icon COLOR_
 */
function getIconColor(variant: SideNavVariant, isActive: boolean): EverColor | undefined {
    switch (variant) {
        case SideNavVariant.PAGE_ORIENTED:
            return isActive ? EverColor.WHITE : EverColor.PARCHMENT_30;
        case SideNavVariant.VIEW_ORIENTED:
            return undefined;
    }
}

/**
 * Returns the handler for UI events on a navigation item.
 */
function useUiEventListener(
    onClick: EventListener,
    disabled?: boolean,
    isSub?: boolean,
    focusParent?: () => void,
) {
    return useBrandedCallback(
        (event: Event) => {
            if (disabled) {
                return;
            }
            if (event instanceof MouseEvent) {
                onClick(event);
            } else if (event instanceof KeyboardEvent) {
                if (event.key === " " || event.key === "Enter") {
                    onClick(event);
                } else if (isSub && event.key === "Escape") {
                    focusParent && focusParent();
                }
            }
        },
        [disabled, focusParent, isSub, onClick],
    );
}

/**
 * Base navigation item which is used to create all the variations of navigation items:
 * {@link Item} - which has two variations: standard and sub, {@link ComplexItem}, {@link
 * ParentItem} Takes in {@link BaseItemProps} which contains props for internal use only.
 */
export const BaseItem: FFC<HTMLDivElement, BaseItemProps> = forwardRef(
    (
        {
            everId,
            label,
            icon,
            iconColor,
            rightElements,
            collapsed,
            disabled,
            itemClasses,
            extraContent,
            isSub,
            subItems,
            divider,
            collapsedItem,
        },
        ref,
    ) => {
        icon &&= React.cloneElement(icon, { color: icon.props.color || iconColor, size: "20px" });
        const { tooltipTargetProps, tooltipComponent } = useEllipsisTooltip({
            targetClassName: "bb-nav-item__label",
            children: label,
            placement: TooltipPlacement.RIGHT,
            "aria-hidden": true,
        });

        const labelId = useId();
        const internalRef = useRef<HTMLDivElement>(null);
        const combinedRef = useCombinedRef(ref, internalRef);
        // TODO move the onClick handler in here
        const { buttonProps } = useButtonRole(internalRef, {
            "aria-labelledby": labelId,
            "aria-disabled": disabled,
        });

        const item = (
            <>
                <div className={"bb-nav-item-focus-container"}>
                    <div
                        {...buttonProps}
                        ref={combinedRef}
                        className={itemClasses}
                        {...everIdProp(everId)}
                    >
                        <div className={"bb-nav-item__primary-content"}>
                            <div className={"bb-nav-item__label-info"}>
                                {icon && React.cloneElement(icon, { size: SMALL_SIZE })}
                                <div {...tooltipTargetProps} id={labelId}>
                                    {label}
                                </div>
                                {tooltipComponent}
                            </div>
                            <div>{rightElements}</div>
                        </div>
                        {extraContent}
                    </div>
                </div>
                {subItems}
                {divider && <div className={"bb-side-nav__divider"} />}
            </>
        );
        return collapsed && collapsedItem ? collapsedItem : item;
    },
);

export interface ItemProps extends CommonItemProps {
    /**
     * Is the item a sub-item of a parent navigation item, or a standard top-level navigation item.
     */
    itemType: ItemType;
    /**
     * For sub-items, a function to focus the parent item on escape key press.
     */
    focusParent?: () => void;
}

/**
 * Standard navigation item categorized into two types: sub, and standard {@link ItemType}.
 * Standard navigation items have no sub-items. Sub navigation items are the sub-items of parent
 * navigation items (see {@link ParentItem} below).
 */
export const Item: FC<ItemProps> = ({
    everId,
    label,
    active,
    onClick = () => {},
    itemType,
    icon,
    rightElements,
    focusParent,
    collapsed,
    disabled,
}) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const sub = itemType === ItemType.SUB;
    const itemClasses = clsx("bb-nav-item", {
        "bb-nav-item--active": active && !disabled,
        "bb-nav-item--sub": sub,
        "bb-nav-item--disabled": disabled,
    });
    const variant = useContext(VariantContext);
    const iconColor = getIconColor(variant, active);

    const rightDivRef = useRef<HTMLDivElement>(null);
    const rightElementsDiv = rightElements && (
        <div ref={rightDivRef} className={"bb-nav-item__right-elements"}>
            {rightElements}
        </div>
    );
    useFilteredEventListener(
        itemRef,
        ["click", "keydown"],
        useUiEventListener(onClick, disabled, sub, focusParent),
        useCssSelectorFilter(itemRef),
    );

    const collapsedItemRef = useRef<HTMLDivElement>(null);
    const { buttonProps } = useButtonRole(collapsedItemRef, {
        "aria-label": "expand item",
        "aria-disabled": disabled,
    });
    const collapsedItem = (
        <div
            {...buttonProps}
            className={itemClasses}
            ref={collapsedItemRef}
            {...everIdProp(everId)}
        >
            {icon && React.cloneElement(icon, { size: SMALL_SIZE })}
        </div>
    );
    useFilteredEventListener(
        collapsedItemRef,
        ["click", "keydown"],
        useUiEventListener(onClick, disabled, sub, focusParent),
        useCssSelectorFilter(collapsedItemRef),
    );

    return (
        <BaseItem
            everId={everId}
            ref={itemRef}
            label={label}
            active={active}
            icon={icon}
            iconColor={iconColor}
            collapsed={collapsed}
            disabled={disabled}
            itemClasses={itemClasses}
            isSub={sub}
            rightElements={rightElementsDiv}
            collapsedItem={collapsedItem}
        />
    );
};

export interface ComplexItemProps extends Omit<CommonItemProps, "variant" | "collapsed"> {
    /**
     * Complex navigation items can have a second line of content in this optional
     * parameter.
     */
    extraContent?: ReactNode;
}

/**
 * Complex navigation items only exist in the View Oriented variant of side navigation
 * {@link SideNavVariant}.
 * They can have a user badge to the right of the label and an extra line of content below the
 * label. Complex items have dividers between them. They do not have a collapsed form since they
 * are not used in collapsible side navigations.
 */
export const ComplexItem: FC<ComplexItemProps> = ({
    everId,
    label,
    active,
    onClick = () => {},
    icon,
    rightElements,
    extraContent,
    disabled,
}) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const itemClasses = clsx("bb-nav-item", "bb-nav-item--complex", {
        "bb-nav-item--active": active && !disabled,
        "bb-nav-item--disabled": disabled,
    });
    const iconColor = getIconColor(SideNavVariant.VIEW_ORIENTED, active);
    const rightDivRef = useRef<HTMLDivElement>(null);
    const rightElementsDiv = rightElements && (
        <div ref={rightDivRef} className={"bb-nav-item__right-elements"}>
            {rightElements}
        </div>
    );
    const extraContentDiv = extraContent && (
        <div className={"bb-nav-item__extra-content"}>{extraContent}</div>
    );
    useFilteredEventListener(
        itemRef,
        ["click", "keydown"],
        useUiEventListener(onClick),
        useCssSelectorFilter(itemRef),
    );

    return (
        <BaseItem
            everId={everId}
            ref={itemRef}
            label={label}
            active={active}
            icon={icon}
            iconColor={iconColor}
            collapsed={false}
            disabled={disabled}
            itemClasses={itemClasses}
            rightElements={rightElementsDiv}
            extraContent={extraContentDiv}
            divider={true}
        />
    );
};

export interface ParentItemProps extends CommonItemProps {
    /**
     * Sub-items of the navigation item.
     */
    children: ReactElement<ItemProps> | ReactElement<ItemProps>[];
    /**
     * Whether the item or any of its children are currently selected. Needed because the icon and
     * text of the parent navigation item should be the color of when it is active (but have no
     * other active styling).
     */
    activeFamily: boolean;
    /**
     * If a parent item has its own active state then it can be selected and has its own page.
     * Otherwise, only its sub-items have their own pages and can be selected.
     */
    hasActiveState: boolean;
    /**
     * Popover menu content containing sub-items as options for a parent item in collapsed form.
     */
    collapsedMenuContent?: ReactNode;
}

/**
 * Parent navigation items are navigation items that can have sub-items.
 * The `children` parameter holds the sub-items for these items.
 * Unlike other types of navigation items, parent navigation items cannot have a popover menu.
 */
export const ParentItem: FC<ParentItemProps> = ({
    everId,
    children,
    label,
    hasActiveState,
    active,
    activeFamily,
    onClick = () => {},
    icon,
    collapsed,
    collapsedMenuContent,
    disabled,
}) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const itemClasses = clsx("bb-nav-item", {
        "bb-nav-item--active": active && !disabled,
        "bb-nav-item--active-child": activeFamily && !disabled,
        "bb-nav-item--disabled": disabled,
    });
    const variant = useContext(VariantContext);
    const iconColor = getIconColor(variant, activeFamily);

    // State of whether sub navigation items are expanded.
    const [expanded, setExpanded] = useState<boolean>(activeFamily);
    const onClickExpand = (e: Event) => {
        if (hasActiveState && !expanded) {
            setExpanded(true);
        } else if (!hasActiveState && !activeFamily) {
            setExpanded(!expanded);
        }
        onClick(e);
    };
    // If a parent item or any of its sub-items are active (`activeFamily`), then the sub-items
    // should be expanded. If the parent item doesn't have its own active state, then sub-items
    // don't automatically expand or collapse based on their active state.
    useEffect(() => {
        if (hasActiveState) {
            setExpanded(!!activeFamily);
        } else if (activeFamily) {
            setExpanded(true);
        }
    }, [activeFamily, hasActiveState, expanded]);

    const rightElements = (
        <div className={"bb-nav-item__right-elements"}>
            {expanded ? (
                <Icon.ChevronDown aria-label={"Collapse sub-items"} color={iconColor} size={20} />
            ) : (
                <Icon.ChevronRight aria-label={"Expand sub-items"} color={iconColor} size={20} />
            )}
        </div>
    );
    children = wrap(children).map((child) => {
        return React.cloneElement(child, { focusParent: () => itemRef.current?.focus() });
    });
    const subItems = (
        <Expandable id={useId()} expanded={expanded}>
            {children}
        </Expandable>
    );
    useFilteredEventListener(
        itemRef,
        ["click", "keydown"],
        useUiEventListener(onClickExpand),
        useCssSelectorFilter(itemRef),
    );
    // Create collapsed menu for parent item if side nav is collapsed.
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const collapsedMenuRef = useRef<HTMLDivElement>(null);
    const { buttonProps } = useButtonRole(collapsedMenuRef, {
        "aria-label": "open menu",
        "aria-disabled": disabled,
    });
    const collapsedItem = collapsed && (
        <>
            <div
                {...buttonProps}
                ref={collapsedMenuRef}
                className={itemClasses}
                {...everIdProp(everId)}
            >
                {icon && React.cloneElement(icon, { size: SMALL_SIZE })}
            </div>
            <PopoverMenu
                trigger={collapsedMenuRef}
                placement={PopoverMenuPlacement.RIGHT}
                show={showMenu}
                setShow={setShowMenu}
            >
                {collapsedMenuContent}
            </PopoverMenu>
        </>
    );
    const onCollapsedClick = () => setShowMenu(!showMenu);
    useFilteredEventListener(
        collapsedMenuRef,
        ["click", "keydown"],
        useUiEventListener(onCollapsedClick),
        useCssSelectorFilter(collapsedMenuRef),
    );

    return (
        <BaseItem
            everId={everId}
            ref={itemRef}
            label={label}
            active={active}
            icon={icon}
            iconColor={iconColor}
            collapsed={collapsed}
            disabled={disabled}
            itemClasses={itemClasses}
            rightElements={rightElements}
            subItems={subItems}
            collapsedItem={collapsedItem}
        />
    );
};
