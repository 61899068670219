import { ButtonColor, ButtonProps, ButtonWidth } from "components/Button";
import {
    ProgressTrackerProps,
    ProgressTrackerStep,
} from "components/ProgressTracker/ProgressTracker";
import { Memo, useBrandedCallback, useBrandedState } from "hooks/useBranded";
import { Dispatch, useMemo } from "react";

interface UseProgressTrackerProps {
    /**
     * See {@link ProgressTrackerProps.steps}.
     */
    steps: Memo<ProgressTrackerStep[]>;
    /**
     * The function to call when the next button on the last step is clicked.
     */
    onSubmit: Memo<() => void>;
    /**
     * The label of the next button on the last step. Default "Submit".
     */
    lastButtonLabel?: string;
    /**
     * See {@link ProgressTrackerProps.disabled}.
     */
    disabled?: boolean;
    /**
     * See {@link ProgressTrackerProps.currentStep}.
     *
     * Use this prop along with {@link setCurrentStep} if you want to use a custom state/setter.
     * Otherwise, the hook will set up this state for you.
     *
     * If initializing the progress tracker at a step that is not the first step, all steps
     * appearing before the current step should be marked as completed, optional, or disabled.
     */
    currentStep?: number;
    /**
     * See {@link ProgressTrackerProps.setCurrentStep}.
     *
     * Use this prop along with {@link currentStep} if you want to use a custom state/setter.
     * Otherwise, the hook will set up this state for you.
     */
    setCurrentStep?: Memo<Dispatch<number>>;
    /**
     * See {@link ProgressTrackerProps.completed}.
     *
     * Use this prop along with {@link setCompleted} if you want to use a custom state/setter.
     * Otherwise, the hook will set up this state for you.
     */
    completed?: Memo<boolean[]>;
    /**
     * The setter for the {@link completed} state variable.
     *
     * Use this prop along with {@link completed} if you want to use a custom state/setter.
     * Otherwise, the hook will set up this state for you.
     */
    setCompleted?: Memo<Dispatch<boolean[]>>;
    /**
     * See {@link ProgressTrackerProps.visited}.
     *
     * Use this prop along with {@link setVisited} if you want to use a custom state/setter.
     * Otherwise, the hook will set up this state for you.
     */
    visited?: Memo<boolean[]>;
    /**
     * See {@link ProgressTrackerProps.setVisited}.
     *
     * Use this prop along with {@link visited} if you want to use a custom state/setter.
     * Otherwise, the hook will set up this state for you.
     */
    setVisited?: Memo<Dispatch<boolean[]>>;
}

type ProgressTrackerButtonProps = Pick<
    ButtonProps,
    "color" | "width" | "disabled" | "onClick" | "children"
>;

interface UseProgressTrackerResult {
    /**
     * Props that can be directly passed into the {@link ProgressTracker} component. Generally,
     * these should not be modified before passing into {@link ProgressTracker}, as that can cause
     * discrepancies in behavior of the previous/next buttons.
     */
    progressTrackerProps: Pick<
        ProgressTrackerProps,
        | "steps"
        | "currentStep"
        | "setCurrentStep"
        | "completed"
        | "visited"
        | "setVisited"
        | "disabled"
    >;
    /**
     * The setter for the {@link progressTrackerProps.completed} state. Use this to update the
     * state when the user completes a step or undoes completion on a step.
     */
    setCompleted: Memo<Dispatch<boolean[]>>;
    /**
     * Props that can be passed directly into the "Previous" button.
     */
    previousButtonProps: ProgressTrackerButtonProps;
    /**
     * Props that can be passed directly into the "Next" button.
     */
    nextButtonProps: ProgressTrackerButtonProps;
    /**
     * A function that resets the progress tracker.
     *
     * Resets {@link visited} to false for all steps, and resets {@link currentStep} to the
     * first non-disabled step.
     *
     * If `resetCompleted`, also resets {@link completed} to false for all steps.
     */
    reset: Memo<(resetCompleted?: boolean) => void>;
}

/**
 * A hook that sets up state variables to be passed into the {@link ProgressTracker} component,
 * as well as provides props for "previous" and "next" navigation buttons.
 */
export function useProgressTracker({
    steps,
    onSubmit,
    lastButtonLabel = "Submit",
    disabled,
    currentStep: currentStepProp,
    setCurrentStep: setCurrentStepProp,
    completed: completedProp,
    setCompleted: setCompletedProp,
    visited: visitedProp,
    setVisited: setVisitedProp,
}: UseProgressTrackerProps): UseProgressTrackerResult {
    const [currentStepInternal, setCurrentStepInternal] = useBrandedState(0);
    const currentStep =
        currentStepProp != null && setCurrentStepProp ? currentStepProp : currentStepInternal;
    const setCurrentStep = setCurrentStepProp || setCurrentStepInternal;

    const [completedInternal, setCompletedInternal] = useBrandedState<boolean[]>(
        [...Array(steps.length).keys()].map((_) => false),
    );
    const completed = completedProp && setCompletedProp ? completedProp : completedInternal;
    const setCompleted = setCompletedProp || setCompletedInternal;

    const [visitedInternal, setVisitedInternal] = useBrandedState<boolean[]>(() => {
        const visitedArray: boolean[] = [];
        for (let i = 0; i < steps.length; i++) {
            visitedArray.push(i <= currentStep);
        }
        return visitedArray;
    });
    const visited = visitedProp && setVisitedProp ? visitedProp : visitedInternal;
    const setVisited = setVisitedProp || setVisitedInternal;

    let prevStep: number | null = null;
    if (currentStep > 0) {
        for (let i = currentStep - 1; i >= 0; i--) {
            if (!steps[i]?.disabledReason) {
                prevStep = i;
                break;
            }
        }
    }
    const previousButtonProps: ProgressTrackerButtonProps = useMemo(() => {
        return {
            width: ButtonWidth.FIXED,
            color: ButtonColor.SECONDARY,
            disabled: disabled || prevStep === null,
            onClick: () => prevStep !== null && setCurrentStep(prevStep),
            children: "Previous",
        };
    }, [disabled, prevStep, setCurrentStep]);

    const nextButtonProps: ProgressTrackerButtonProps = useMemo(() => {
        // The step that the "Next" button should navigate to
        let nextStep: number | null = null;
        const isLastStep = currentStep >= completed.length - 1;
        if (!isLastStep) {
            for (let i = currentStep + 1; i < steps.length; i++) {
                if (!steps[i]?.disabledReason) {
                    nextStep = i;
                    break;
                }
            }
        }
        return {
            width: ButtonWidth.FIXED,
            color: ButtonColor.PRIMARY,
            disabled:
                disabled
                || (!isLastStep && nextStep === null)
                || (!completed[currentStep] && !steps[currentStep]?.optional),
            onClick: () => {
                if (isLastStep) {
                    onSubmit();
                } else {
                    nextStep !== null && setCurrentStep(nextStep);
                }
            },
            children: isLastStep
                ? lastButtonLabel
                : steps[currentStep]?.optional && !completed[currentStep]
                  ? "Skip"
                  : "Next",
        };
    }, [completed, currentStep, disabled, lastButtonLabel, onSubmit, setCurrentStep, steps]);

    const reset = useBrandedCallback(
        (resetCompleted?: boolean) => {
            resetCompleted && setCompleted([...Array(steps.length).keys()].map((_) => false));
            setVisited([...Array(steps.length).keys()].map((_) => false));
            const firstStepIndex = steps.findIndex((step) => !step.disabledReason);
            setCurrentStep(firstStepIndex === -1 ? 0 : firstStepIndex);
        },
        [setCompleted, setCurrentStep, setVisited, steps],
    );

    return useMemo(() => {
        return {
            progressTrackerProps: {
                steps,
                currentStep,
                setCurrentStep,
                completed,
                visited,
                setVisited,
                disabled,
            },
            setCompleted: setCompleted,
            previousButtonProps,
            nextButtonProps,
            reset,
        };
    }, [
        completed,
        currentStep,
        disabled,
        nextButtonProps,
        previousButtonProps,
        reset,
        setCompleted,
        setCurrentStep,
        setVisited,
        steps,
        visited,
    ]);
}
