import { BaseButtonProps, BaseSplitButtonProps } from "components/Button/BaseButton";
import { CounterColor, CounterProps } from "components/Counter/Counter";
import { FormSubmitButtonProps } from "components/Form";
import { IconProps } from "components/Icon/IconProps";
import * as CommonIcon from "components/Icon/CommonIcon";
import { everHashProp } from "EverAttribute/EverHash";
import { everIdProp } from "EverAttribute/EverId";
import * as ButtonTokens from "tokens/typescript/ButtonTokens";
import React, {
    cloneElement,
    CSSProperties,
    Dispatch,
    FC,
    forwardRef,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    Ref,
    useEffect,
    useState,
} from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverColor } from "tokens/typescript/EverColor";
import * as Icon from "components/Icon";
import clsx from "clsx";
import { SEC } from "core";
import { FFC } from "util/type";
import CSS from "csstype";
import { clamp as cssClamp } from "util/css";
import "../Button.scss";
import { useLatest } from "hooks/useLatest";

export enum ButtonColor {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    DANGER = "danger",
    WARNING = "warning",
    STORYBUILDER = "storybuilder",
}

export enum ButtonSize {
    SMALL = "small",
    LARGE = "large",
}

export enum ButtonWidth {
    FIXED = "fixed",
    FLEXIBLE = "flexible",
    FULL = "full",
}

export interface ButtonProps extends BaseButtonProps<ReactNode> {
    /**
     * The color of the button. Any of (primary, secondary, warning, danger, storybuilder).
     *
     * primary: everblue
     * secondary: gray
     * warning, storybuilder: yellow
     * danger: red
     *
     * Defaults to primary.
     */
    color?: ButtonColor;
    /**
     * An optional counter that, if provided, is displayed on the right hand side of the button.
     * Counters should only be used on primary and secondary buttons. If the counter contains an
     * icon, the icon's color should take into account the {@link color} of the button.
     */
    counter?: ReactElement<CounterProps>;
    /**
     * An optional boolean that, if true, adds a caret down icon to the right hand side of the
     * button.
     */
    dropdown?: boolean;
    /**
     * If {@link dropdown} is true, the ref to use for the caret down icon.
     */
    dropdownRef?: Ref<SVGSVGElement>;
    /**
     * The id of the form this is the submit button for. Only necessary when the button is outside
     * the given form.
     *
     * If isSubmit is false, has no effect.
     */
    form?: string;
    /**
     * An optional icon to add to the left hand side of the button. May be overridden by loading.
     *
     * Its color will be overridden based on the color of the button, and its size will be
     * overridden to 20.
     */
    icon?: ReactElement<IconProps>;
    /**
     * If true, makes the button type "submit".
     */
    isSubmit?: boolean;
    /**
     * An optional boolean that, if true, replaces the icon for the button with a loading icon and
     * disables the button.
     */
    loading?: boolean;
    /**
     * The minimum width for the button. Only has an effect if the button's width is
     * {@link ButtonWidth.FLEXIBLE}.
     */
    minWidth?: CSS.Property.MinWidth;
    /**
     * The size of the button. Any of (small, large). Determines the height and, along with the
     * width parameter, width of the button.
     *
     * Defaults to large.
     */
    size?: ButtonSize;
    /**
     * The width of the button. Any of (fixed, full, flexible).
     *
     * fixed: The button will be one set width depending on the size parameter.
     * full: The button will take up the full size of its container, up to 400px.
     * flexible: The button will expand or shrink to fit the content inside it, up to 400px.
     *
     * Defaults to flexible. If the button is a dropdown, has a counter, or has an icon, will be
     * overridden to be flexible regardless of the value provided.
     */
    width?: ButtonWidth;
}

export type SplitButtonProps = BaseSplitButtonProps<
    Omit<ButtonProps, "width" | "dropdown" | "dropdownRef" | "counter" | "style">
>;

function iconColor(color: ButtonColor): EverColor {
    switch (color) {
        case ButtonColor.PRIMARY:
            return ColorTokens.BUTTON_ICON_PRIMARY;
        case ButtonColor.SECONDARY:
            return ColorTokens.BUTTON_ICON_SECONDARY;
        case ButtonColor.DANGER:
            return ColorTokens.BUTTON_ICON_DANGER;
        case ButtonColor.WARNING:
            return ColorTokens.BUTTON_ICON_WARNING;
        case ButtonColor.STORYBUILDER:
            return ColorTokens.BUTTON_ICON_STORYBUILDER;
    }
}

// No buttons should be without children, but we make children optional for this internal component
// so that split button can reuse the functionality of this component for the side button
const ButtonWithOptionalChildren: FFC<
    HTMLButtonElement,
    Omit<ButtonProps, "children"> & Partial<Pick<ButtonProps, "children">>
> = forwardRef(
    (
        {
            color = ButtonColor.PRIMARY,
            size = ButtonSize.LARGE,
            width = ButtonWidth.FLEXIBLE,
            children,
            className,
            id,
            everId,
            onClick,
            onMouseDown,
            disabled,
            active,
            autoFocus,
            counter,
            dropdown,
            dropdownRef,
            loading,
            icon,
            isSubmit,
            form,
            minWidth,
            tabFocusable = true,
            style,
            ...props
        },
        ref,
    ) => {
        disabled ||= loading;
        if (icon || dropdown || counter !== undefined) {
            width = ButtonWidth.FLEXIBLE;
        }
        if (width !== ButtonWidth.FLEXIBLE) {
            minWidth = undefined;
        } else if (minWidth !== undefined) {
            minWidth = cssClamp(
                minWidth,
                size === ButtonSize.SMALL
                    ? ButtonTokens.MIN_WIDTH_SMALL
                    : ButtonTokens.MIN_WIDTH_LARGE,
                ButtonTokens.MAX_WIDTH,
            );
        }
        if (loading) {
            // This is placed after the icon check above because we don't want to override the width
            // for loading icons
            icon = <CommonIcon.Loading />;
        }
        className = clsx(
            "bb-button",
            `bb-button--${color}`,
            `bb-button--${size}`,
            `bb-button--${width}`,
            className,
            { "bb-button--loading": loading, "bb-button--active": active },
        );
        if (icon) {
            const overrides: IconProps = {
                className: clsx(icon.props.className, "bb-button__icon"),
                size: 20,
                color: icon.props.color || iconColor(color),
                "aria-hidden": true,
            };
            icon = React.cloneElement(icon, overrides);
        }
        if (counter) {
            counter =
                color === ButtonColor.PRIMARY || color === ButtonColor.SECONDARY
                    ? React.cloneElement(counter, {
                          className: clsx(counter.props.className, "bb-button__counter"),
                          color:
                              color === ButtonColor.PRIMARY
                                  ? CounterColor.TRANSPARENT
                                  : CounterColor.DEFAULT,
                          hasWhiteBackground: color !== ButtonColor.SECONDARY,
                      })
                    : undefined; // Counter should only be used on primary and secondary buttons.
        }
        return (
            <button
                onClick={(e) => !disabled && onClick?.(e)}
                onMouseDown={(e) => !disabled && onMouseDown?.(e)}
                aria-disabled={disabled}
                className={className}
                autoFocus={autoFocus}
                type={isSubmit ? "submit" : "button"}
                ref={ref}
                form={isSubmit ? form : undefined}
                style={{ minWidth, ...style }}
                id={id}
                tabIndex={!tabFocusable ? -1 : undefined}
                {...everIdProp(everId)}
                {...(typeof children === "string" ? everHashProp(children) : {})}
                {...props}
            >
                <span className={"bb-button__content"}>
                    {icon}
                    {children && <span className={"bb-button__label"}>{children}</span>}
                    {counter}
                    {dropdown && (
                        <Icon.CaretDown
                            ref={dropdownRef}
                            className={"bb-button__icon"}
                            size={20}
                            color={iconColor(color)}
                            aria-label={"Expand"}
                        />
                    )}
                </span>
            </button>
        );
    },
);
ButtonWithOptionalChildren.displayName = "ButtonWithOptionalChildren";

/**
 * A standard button component.
 */
export const Button: FFC<HTMLButtonElement, ButtonProps> = forwardRef((props, ref) => (
    <ButtonWithOptionalChildren {...props} ref={ref} />
));
Button.displayName = "Button";

/**
 * Utility function to create a button or mix in other props into an already created button.
 *
 * @param button The button to mix props into, or the text to use for the text of the button.
 * If null is provided, null is returned.
 * @param props A partial set of props to mix into the button.
 */
export function generateButton<T extends ButtonProps | FormSubmitButtonProps>(
    button: ReactElement<T> | string | null,
    props: Partial<T> = {},
): ReactElement<T> | null {
    if (React.isValidElement(button)) {
        const combinedProps: T = {
            ...props,
            // Allow externally specified props to override internal defaults
            ...button.props,
        };
        return React.cloneElement(button, combinedProps);
    } else if (button !== null) {
        return <Button children={button} {...props} />;
    } else {
        return null;
    }
}

/**
 * A button split up into two parts, a main button and a side button. The main button performs
 * some primary action, while the side button allows access to some secondary actions.
 */
export const SplitButton: FC<SplitButtonProps> = ({
    "sideButton-aria-label": sideButtonLabel = "Expand",
    "sideButton-aria-describedby": sideButtonDescribedby,
    "sideButton-aria-expanded": sideButtonExpanded,
    "sideButton-aria-controls": sideButtonControls,
    "mainButton-aria-label": mainButtonLabel,
    "mainButton-aria-describedby": mainButtonDescribedby,
    "mainButton-aria-expanded": mainButtonExpanded,
    "mainButton-aria-controls": mainButtonControls,
    mainButtonEverId,
    sideButtonEverId,
    sideButtonIcon = <Icon.CaretDown />,
    tabFocusable = true,
    ...props
}) => {
    const className = clsx("bb-split-button", props.className);
    return (
        <span className={className}>
            <Button
                ref={props.mainButtonRef}
                color={props.color}
                disabled={props.mainButtonDisabled || props.disabled}
                size={props.size}
                onClick={props.onMainButtonClick}
                onMouseDown={props.onMainButtonMouseDown}
                loading={props.loading}
                icon={props.icon}
                className={"bb-split-button__main-button"}
                id={props.mainButtonId}
                width={ButtonWidth.FLEXIBLE}
                active={props.mainButtonActive}
                aria-label={mainButtonLabel}
                aria-describedby={mainButtonDescribedby}
                aria-expanded={mainButtonExpanded}
                aria-controls={mainButtonControls}
                tabFocusable={tabFocusable}
                minWidth={props.minWidth}
                everId={mainButtonEverId}
                form={props.form}
                isSubmit={props.isSubmit}
                autoFocus={props.mainButtonAutoFocus}
            >
                {props.children}
            </Button>
            <ButtonWithOptionalChildren
                ref={props.sideButtonRef}
                color={props.color}
                disabled={props.sideButtonDisabled || props.disabled}
                icon={sideButtonIcon}
                size={props.size}
                className={"bb-split-button__side-button"}
                id={props.sideButtonId}
                onClick={props.onSideButtonClick}
                onMouseDown={props.onSideButtonMouseDown}
                active={props.sideButtonActive}
                aria-label={sideButtonLabel}
                aria-describedby={sideButtonDescribedby}
                aria-expanded={sideButtonExpanded}
                aria-controls={sideButtonControls}
                tabFocusable={tabFocusable}
                everId={sideButtonEverId}
                autoFocus={props.sideButtonAutoFocus}
            />
        </span>
    );
};

const TIMED_BUTTON_TIMER_DURATION = 2000;

export interface TimedButtonProps {
    /**
     * The button to display initially. When this button is clicked, the timer will start.
     */
    children: ReactElement<ButtonProps>;
    /**
     * The button to display once the timer has begun and after the timer has finished.
     * This is the button that the background sliding animation will play on.
     * If not provided, defaults to {@link children}.
     */
    confirmButton?: ReactElement<ButtonProps>;
    /**
     * The function to call when the user clicks the {@link confirmButton} after the
     * timer/sliding animation has completed.
     */
    onConfirm: MouseEventHandler<HTMLButtonElement>;
    /**
     * A state variable indicating whether the timer/sliding animation has started.
     * This should usually be set to false initially.
     *
     * Setting this prop to `true` starts the timer programmatically (no need to set it in
     * response to the user clicking the button, since that starts the timer automatically).
     * Setting it to `false` resets the button to the initial state.
     */
    timerStarted: boolean;
    /**
     * The setter for the {@link timerStarted} state variable.
     */
    setTimerStarted: Dispatch<boolean>;
}

/**
 * A primary button with a disabled timer state. After the button is initially clicked, it
 * is disabled for 2 seconds while a background sliding animation is played. After the
 * timer/animation has completed, the button is enabled.
 */
export function TimedButton({
    children,
    confirmButton,
    onConfirm,
    timerStarted,
    setTimerStarted,
}: TimedButtonProps) {
    confirmButton ||= children;
    const [timerFinished, setTimerFinished] = useState(false);
    const [timer, setTimer] = useState<number | null>(null);
    const timerRef = useLatest(timer);

    useEffect(() => {
        if (timerStarted) {
            setTimer(
                window.setTimeout(() => {
                    setTimerFinished(true);
                }, TIMED_BUTTON_TIMER_DURATION),
            );
        } else {
            setTimerStarted(false);
            setTimerFinished(false);
            timerRef.current && window.clearTimeout(timerRef.current);
            setTimer(null);
        }
    }, [timerStarted, setTimerStarted, timerRef]);

    if (!timerStarted && !timerFinished) {
        return cloneElement(children, {
            className: clsx(children.props.className, "bb-timed-button"),
            color: ButtonColor.PRIMARY,
            onClick: () => {
                setTimerStarted(true);
            },
        });
    }

    return cloneElement(confirmButton, {
        color: ButtonColor.PRIMARY,
        onClick: onConfirm,
        disabled: !timerFinished || confirmButton.props.disabled,
        className: clsx(confirmButton.props.className, "bb-timed-button", {
            "bb-timed-button--sliding": !timerFinished,
        }),
        style: {
            "--bb-timedButton-duration": TIMED_BUTTON_TIMER_DURATION / SEC + "s",
        } as CSSProperties,
    });
}
