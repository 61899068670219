import clsx from "clsx";
import * as Icon from "components/Icon";
import {
    getDividerPlacementStyles,
    getNumAvailableSlots,
    getStepId,
    getVisibleWindow,
    LEFT_PLACEHOLDER,
    ProgressTrackerOrientation,
    RIGHT_PLACEHOLDER,
} from "components/ProgressTracker/ProgressTrackerUtil";
import { Heading, HeadingElement, HeadingMargin, HeadingVariant, Span } from "components/Text";
import { Tooltip, TooltipPlacement } from "components/Tooltip";
import { Num, RequireAtLeastOne } from "core";
import { ArrowNavDirection, useArrowNav } from "hooks/useArrowNav";
import { Memo } from "hooks/useBranded";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useLatest } from "hooks/useLatest";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { Dispatch, FC, ReactNode, useEffect, useId, useMemo, useRef, useState } from "react";
import { EverColor } from "tokens/typescript/EverColor";
import * as ProgressTrackerTokens from "tokens/typescript/ProgressTrackerTokens";
import "./ProgressTracker.scss";
import { getSizePx } from "util/css";
import { useProgressTracker } from "./useProgressTracker";

export interface ProgressTrackerStep {
    /**
     * The label of the tracker step.
     */
    label: string;
    /**
     * The heading element to use for the step content heading. This prop is ignored if
     * {@link heading} is provided.
     *
     * Defaults to "h2" for horizontal progress trackers and "h3" for vertical ones.
     */
    headingElement?: HeadingElement;
    /**
     * A custom heading to display above the step content. Generally, this should include a
     * medium heading, ending in "(optional)" if the step is {@link optional}. If not provided,
     * then a reasonable default will be created using {@link label}.
     */
    heading?: ReactNode;
    /**
     * Whether the step is optional. If true, then the user should be able to move to the next
     * step without completing this one. Append "(optional)" to the {@link heading}.
     */
    optional?: boolean;
    /**
     * If provided, the step will be disabled and a tooltip will be added to the tracker button
     * with the provided {@link disabledReason}. The user should not be able to navigate to
     * disabled steps. Instead, "Next" or "Previous" buttons should skip over disabled steps.
     */
    disabledReason?: string;
}

export type ProgressTrackerProps = RequireAtLeastOne<
    BaseProgressTrackerProps,
    "aria-label" | "aria-labelledby"
>;

interface BaseProgressTrackerProps {
    /**
     * An optional className to apply to the outer wrapper of the progress tracker.
     */
    className?: string;
    /**
     * The id of another element whose text value provides an accessible name for the progress
     * tracker. For example, if the title of the progress tracker is contained in a heading element
     * above the progress tracker, use the id of that heading element.
     *
     * Either {@link aria-labelledby} or {@link aria-label} must be provided. If both are provided,
     * {@link aria-labelledby} takes precedence.
     */
    "aria-labelledby": string;
    /**
     * An accessible name for the progress tracker.
     *
     * Either {@link aria-labelledby} or {@link aria-label} must be provided. If both are provided,
     * {@link aria-labelledby} takes precedence.
     */
    "aria-label": string;
    /**
     * The content of the current step to display in the panel associated with the progress
     * tracker. The content panel is displayed under the progress tracker in the horizontal
     * tracker orientation, and to the right of the progress tracker in the vertical orientation.
     * The content should change depending on the {@link currentStep}, and should be disabled if
     * {@link ProgressTrackerProps.disabled} is true.
     */
    children: ReactNode;
    /**
     * An array of in-order steps to be represented in the progress tracker.
     */
    steps: Memo<ProgressTrackerStep[]>;
    /**
     * The index of the current step in {@link steps}. The current step should never be disabled,
     * as disabled steps should be skipped over.
     *
     * Use the {@link ProgressTracker.use} hook to set up this state variable and its setter.
     */
    currentStep: number;
    /**
     * The setter for the {@link currentStep} state variable.
     */
    setCurrentStep: Memo<Dispatch<number>>;
    /**
     * An array of booleans the same size as {@link steps} which represents whether each of the
     * steps have been completed by the user. A step should be updated to completed when the
     * user completes all necessary form elements in a step, not when they hit the "Next" button.
     *
     * Use the {@link ProgressTracker.use} hook to set up this state variable and its setter.
     */
    completed: Memo<boolean[]>;
    /**
     * An array of booleans the same size as {@link steps} which represents whether each of the
     * steps have been visited. This helps determine the appearance of the step's tracker icon
     * as well as whether the preceding divider line is green.
     *
     * Use the {@link ProgressTracker.use} hook to set up this state variable and its setter.
     * In most cases, you will not need to use {@link UseProgressTrackerResult#visited} for
     * anything except passing into this prop.
     */
    visited: Memo<boolean[]>;
    /**
     * The setter for the {@link visited} state variable.
     */
    setVisited: Memo<Dispatch<boolean[]>>;
    /**
     * The orientation of the progress tracker. Note that progress trackers in the
     * {@link ProgressTrackerOrientation.VERTICAL} orientation are generally only meant for
     * use inside dialogs.
     */
    orientation?: ProgressTrackerOrientation;
    /**
     * Whether the entire progress tracker should be disabled. If true, you should also disable
     * the step content in {@link children}.
     */
    disabled?: boolean;
}

/**
 * A progress tracker for tracking a user's progress through a set of ordered steps. The step
 * content panel (including heading) is built into the component, but navigation buttons
 * ("Previous", "Next", "Submit", etc.) are not. Use the {@link ProgressTracker.use} hook
 * to get default props for navigation buttons.
 * See {@link UseProgressTrackerResult#previousButtonProps} and
 * {@link UseProgressTrackerResult#nextButtonProps}.
 */
export const ProgressTracker: FC<ProgressTrackerProps> & {
    use: typeof useProgressTracker;
} = ({
    className,
    "aria-labelledby": ariaLabelledby,
    "aria-label": ariaLabel,
    children,
    steps,
    currentStep,
    setCurrentStep,
    completed,
    visited,
    setVisited,
    orientation = ProgressTrackerOrientation.HORIZONTAL,
    disabled = false,
}: ProgressTrackerProps) => {
    useEffect(() => {
        if (currentStep < 0 || currentStep > steps.length - 1) {
            setCurrentStep(Num.clamp(currentStep, 0, steps.length - 1));
        }
    }, [currentStep, setCurrentStep, steps.length]);

    const visitedRef = useLatest(visited);
    useEffect(() => {
        // Mark current step as visited if not already marked as visited
        if (visitedRef.current[currentStep]) {
            return;
        }
        const newVisited: boolean[] = [];
        for (let i = 0; i < visitedRef.current.length; i++) {
            newVisited.push(i <= currentStep ? true : visitedRef.current[i]);
        }
        setVisited(newVisited);
    }, [currentStep, setVisited, visitedRef]);

    const [focusInTracker, setFocusInTracker] = useState(false);
    const arrowNavRef = useRef<HTMLDivElement>(null);
    const [resizeRef, resizeEntry] = useResizeObserver();
    const progressTrackerRef = useCombinedRef(arrowNavRef, resizeRef);

    useArrowNav(arrowNavRef, {
        direction:
            orientation === ProgressTrackerOrientation.HORIZONTAL
                ? ArrowNavDirection.LEFT_RIGHT
                : ArrowNavDirection.UP_DOWN,
        tabbableElementsOnly: false,
    });

    // An array of step indices representing the window of currently visible steps.
    // For vertical progress trackers, we always show all steps.
    // For horizontal progress trackers, start with all steps and update with the correct
    // window after container width has been measured.
    const [visibleWindow, setVisibleWindow] = useState<number[]>([...Array(steps.length).keys()]);
    const visibleWindowRef = useLatest(visibleWindow);
    // The number of items (including steps and "More steps" placeholders) that can fit in the
    // current container width.
    const numAvailableSlots = resizeEntry.target?.clientWidth
        ? getNumAvailableSlots(resizeEntry.target.clientWidth)
        : 0;
    useEffect(() => {
        // Update the visible window
        if (orientation === ProgressTrackerOrientation.VERTICAL) {
            if (steps.length !== visibleWindowRef.current.length) {
                // We should only hit this if the number of steps changes, which generally
                // shouldn't happen.
                setVisibleWindow([...Array(steps.length).keys()]);
            }
            return;
        }
        const newWindow = getVisibleWindow(
            visibleWindowRef.current,
            numAvailableSlots,
            currentStep,
            steps.length,
        );
        setVisibleWindow(newWindow);
    }, [currentStep, orientation, numAvailableSlots, steps.length, visibleWindowRef]);

    const trackerId = useId();
    const stepContentId = trackerId + "-step-content";
    const trackerElements: ReactNode[] = useMemo(() => {
        const elements: ReactNode[] = [];
        let lastGreenDividerIndex = 0;
        for (let i = 0; i < visibleWindow.length; i++) {
            const item = visibleWindow[i];
            const stepIndex = item === RIGHT_PLACEHOLDER ? visibleWindow[i - 1] + 1 : item;
            if (
                stepIndex <= currentStep
                || (visited[stepIndex]
                    && (completed[stepIndex]
                        || steps[stepIndex].optional
                        || steps[stepIndex].disabledReason))
            ) {
                lastGreenDividerIndex = i;
            }
        }
        // Whether all previous steps are completed (or disabled, or optional and visited).
        let previousCompleted = true;
        for (let i = 0; i < visibleWindow.length; i++) {
            const item = visibleWindow[i];
            const stepIndex = item === RIGHT_PLACEHOLDER ? visibleWindow[i - 1] + 1 : item;
            if (i > 0) {
                elements.push(
                    <Divider
                        key={`divider_${stepIndex}`}
                        index={i - 1}
                        orientation={orientation}
                        visited={i <= lastGreenDividerIndex}
                    />,
                );
            }
            if (item === LEFT_PLACEHOLDER) {
                elements.push(
                    <Placeholder
                        key={"placeholder_left"}
                        hiddenSteps={steps.slice(0, visibleWindow[1])}
                        visited={true}
                    />,
                );
            } else if (item === RIGHT_PLACEHOLDER) {
                elements.push(
                    <Placeholder
                        key={"placeholder_right"}
                        hiddenSteps={steps.slice(visibleWindow[visibleWindow.length - 2] + 1)}
                        visited={i <= lastGreenDividerIndex}
                    />,
                );
            } else {
                const stepIndex = item;
                const step = steps[stepIndex];
                if (step) {
                    const isClickable =
                        !step.disabledReason
                        && !disabled
                        && currentStep !== stepIndex
                        && previousCompleted;
                    // Update previousCompleted for the next step
                    previousCompleted =
                        previousCompleted
                        && (completed[stepIndex]
                            || !!step.disabledReason
                            || (!!step.optional && visited[stepIndex]));
                    elements.push(
                        <StepButton
                            key={`step_${stepIndex}`}
                            step={step}
                            stepIndex={stepIndex}
                            completed={completed[stepIndex]}
                            orientation={orientation}
                            isCurrent={currentStep === stepIndex}
                            visited={visited[stepIndex]}
                            isClickable={isClickable}
                            onClick={() => setCurrentStep(stepIndex)}
                            focusInTracker={focusInTracker}
                            stepContentId={stepContentId}
                            trackerId={trackerId}
                        />,
                    );
                }
            }
        }
        return elements;
    }, [
        completed,
        currentStep,
        disabled,
        focusInTracker,
        orientation,
        setCurrentStep,
        stepContentId,
        steps,
        trackerId,
        visibleWindow,
        visited,
    ]);

    const headingId = useId();
    if (currentStep < 0 || currentStep > steps.length - 1) {
        return null;
    }
    return (
        <div
            aria-label={ariaLabelledby ? undefined : ariaLabel}
            aria-labelledby={ariaLabelledby}
            className={clsx(
                className,
                "bb-progress-tracker__wrapper",
                `bb-progress-tracker__wrapper--${orientation}`,
                { "bb-progress-tracker__wrapper--disabled": disabled },
            )}
        >
            <div
                role={"tablist"}
                aria-orientation={orientation}
                aria-owns={clsx(steps.map((_, i) => getStepId(trackerId, i)))}
                ref={progressTrackerRef}
                className={clsx("bb-progress-tracker", `bb-progress-tracker--${orientation}`, {
                    "bb-progress-tracker--disabled": disabled,
                    // Hide horizontal progress tracker until the container has been measured and
                    // the visible window has been updated
                    "bb-progress-tracker--hidden":
                        orientation === ProgressTrackerOrientation.HORIZONTAL
                        && (!numAvailableSlots
                            || (visibleWindow.length !== numAvailableSlots
                                && visibleWindow.length !== steps.length)),
                })}
                onFocus={() => setFocusInTracker(true)}
                onBlur={() => setFocusInTracker(false)}
            >
                <div className={"bb-progress-tracker__steps-container"}>{trackerElements}</div>
            </div>
            <div
                id={stepContentId}
                className={"bb-progress-tracker__step-content"}
                aria-labelledby={headingId}
                aria-disabled={disabled}
                role={"tabpanel"}
            >
                <div id={headingId} className={"bb-progress-tracker__step-heading"}>
                    {steps[currentStep].heading || (
                        <Heading
                            element={
                                orientation === ProgressTrackerOrientation.HORIZONTAL ? "h2" : "h3"
                            }
                            variant={HeadingVariant.MEDIUM}
                            marginType={HeadingMargin.NONE}
                        >
                            {steps[currentStep].label}
                            {steps[currentStep].optional ? " (optional)" : ""}
                        </Heading>
                    )}
                </div>
                {children}
            </div>
        </div>
    );
};

interface StepButtonProps {
    step: ProgressTrackerStep;
    stepIndex: number;
    completed: boolean;
    orientation: ProgressTrackerOrientation;
    isCurrent: boolean;
    visited: boolean;
    isClickable: boolean;
    onClick: () => void;
    focusInTracker: boolean;
    stepContentId: string;
    trackerId: string;
}

/**
 * A step button containing a status icon and step label which can be clicked to navigate to the
 * corresponding step (when enabled).
 */
function StepButton({
    step: { label, optional, disabledReason },
    stepIndex,
    completed,
    orientation,
    isCurrent,
    visited,
    isClickable,
    onClick,
    focusInTracker,
    stepContentId,
    trackerId,
}: StepButtonProps): ReactNode {
    let statusIcon;
    const statusIconProps = {
        className: "bb-progress-tracker__step-status-icon",
        size:
            orientation === ProgressTrackerOrientation.HORIZONTAL
                ? getSizePx(ProgressTrackerTokens.STATUS_ICON_SIZE_HORIZONTAL)
                : getSizePx(ProgressTrackerTokens.STATUS_ICON_SIZE_VERTICAL),
    };
    if (isCurrent) {
        statusIcon = (
            <Icon.ProgressTrackerInProgress
                color={EverColor.GREEN_40}
                aria-label={"in progress"}
                {...statusIconProps}
            />
        );
    } else if (completed || (visited && (optional || disabledReason))) {
        statusIcon = (
            <Icon.CircleCheckFilled
                color={EverColor.GREEN_40}
                aria-label={"complete"}
                {...statusIconProps}
            />
        );
    } else {
        statusIcon = (
            <Icon.ProgressTrackerNotStarted
                color={EverColor.PARCHMENT_40}
                aria-label={"not started"}
                {...statusIconProps}
            />
        );
    }

    const [resizeRef, resizeEntry] = useResizeObserver();
    const labelEllipsed =
        resizeEntry.target && resizeEntry.target.scrollWidth > resizeEntry.target.clientWidth;
    const tooltipId = useId();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const LabelSpan = isCurrent ? Span.SmallSemibold : Span.Small;

    return (
        <div className={"bb-progress-tracker__step-wrapper"}>
            <button
                id={getStepId(trackerId, stepIndex)}
                className={clsx("bb-progress-tracker__step", {
                    "bb-progress-tracker__step--disabled": disabledReason,
                })}
                role={"tab"}
                aria-controls={stepContentId}
                aria-selected={isCurrent}
                aria-describedby={tooltipId}
                aria-disabled={!isClickable}
                onClick={isClickable ? onClick : undefined}
                ref={buttonRef}
                tabIndex={isCurrent && !focusInTracker ? 0 : -1}
            >
                {statusIcon}
                <div className={"bb-progress-tracker__step-label"}>
                    <LabelSpan
                        ref={resizeRef}
                        className={clsx("bb-progress-tracker__step-label-main", {
                            "bb-text--color-secondary":
                                !completed && !isCurrent && !(optional && visited),
                        })}
                    >
                        {label}
                    </LabelSpan>
                    {optional && (
                        <Span.Small className={"bb-text--color-secondary"}>(optional)</Span.Small>
                    )}
                </div>
            </button>
            {(disabledReason || labelEllipsed) && (
                <Tooltip
                    id={tooltipId}
                    target={buttonRef}
                    aria-hidden={!disabledReason}
                    placement={
                        orientation === ProgressTrackerOrientation.HORIZONTAL
                            ? [
                                  TooltipPlacement.BOTTOM,
                                  TooltipPlacement.BOTTOM_START,
                                  TooltipPlacement.BOTTOM_END,
                              ]
                            : [TooltipPlacement.RIGHT]
                    }
                >
                    {disabledReason || label}
                </Tooltip>
            )}
        </div>
    );
}

interface PlaceholderProps {
    hiddenSteps: ProgressTrackerStep[];
    visited: boolean;
}

/**
 * A placeholder progress tracker item for representing steps that aren't shown in the current
 * view. The label indicates how many steps are hidden, and it has a tooltip which lists each
 * hidden step.
 */
function Placeholder({ hiddenSteps, visited }: PlaceholderProps): ReactNode {
    const tooltipId = useId();
    const placeholderRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <div className={"bb-progress-tracker__placeholder-wrapper"}>
                <div
                    className={"bb-progress-tracker__placeholder"}
                    aria-describedby={tooltipId}
                    tabIndex={-1} // We want this to be focusable only through useArrowNav
                    ref={placeholderRef}
                >
                    <Icon.Dots
                        color={visited ? EverColor.GREEN_40 : EverColor.PARCHMENT_40}
                        aria-hidden={true}
                    />
                    <Span.Small
                        className={clsx({
                            "bb-text--color-secondary": !visited,
                        })}
                    >
                        {hiddenSteps.length} more steps
                    </Span.Small>
                </div>
            </div>
            <Tooltip
                id={tooltipId}
                target={placeholderRef}
                className={"bb-progress-tracker__placeholder-tooltip"}
                placement={[
                    TooltipPlacement.BOTTOM,
                    TooltipPlacement.BOTTOM_START,
                    TooltipPlacement.BOTTOM_END,
                ]}
            >
                <ul>
                    {hiddenSteps.map((step) => (
                        <li key={step.label}>{step.label}</li>
                    ))}
                </ul>
            </Tooltip>
        </>
    );
}

interface DividerProps {
    index: number;
    orientation: ProgressTrackerOrientation;
    visited: boolean;
}

function Divider({ index, orientation, visited }: DividerProps): ReactNode {
    return (
        <div
            className={clsx("bb-progress-tracker__divider", {
                "bb-progress-tracker__divider--visited": visited,
            })}
            style={getDividerPlacementStyles(index, orientation)}
        >
            <div className={"bb-progress-tracker__divider-fill"} />
        </div>
    );
}

ProgressTracker.use = useProgressTracker;
export { ProgressTrackerOrientation } from "./ProgressTrackerUtil";
