import { CSSProperties } from "react";
import * as ProgressTrackerTokens from "tokens/typescript/ProgressTrackerTokens";
import { getSizePx } from "util/css";

export enum ProgressTrackerOrientation {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical",
}

// At least need to be able to display "more steps" placeholder on the left, the current step,
// and the "more steps" placeholder on the right.
export const MIN_NUM_ITEMS = 3;
export const LEFT_PLACEHOLDER = -1;
export const RIGHT_PLACEHOLDER = 999;

const STEP_PADDING = getSizePx(ProgressTrackerTokens.STEP_PADDING);
const HORIZONTAL_STEP_WIDTH = getSizePx(ProgressTrackerTokens.STEP_MAX_WIDTH_HORIZONTAL);
const STATUS_ICON_SIZE_HORIZONTAL = getSizePx(ProgressTrackerTokens.STATUS_ICON_SIZE_HORIZONTAL);
const STATUS_ICON_SIZE_VERTICAL = getSizePx(ProgressTrackerTokens.STATUS_ICON_SIZE_VERTICAL);
const DIVIDER_LENGTH_HORIZONTAL = getSizePx(ProgressTrackerTokens.DIVIDER_LENGTH_HORIZONTAL);
const DIVIDER_LENGTH_VERTICAL = getSizePx(ProgressTrackerTokens.DIVIDER_LENGTH_VERTICAL);
const DIVIDER_SPACING = getSizePx(ProgressTrackerTokens.DIVIDER_SPACING);

const HORIZONTAL_SPACE_BETWEEN_DIVIDERS = STATUS_ICON_SIZE_HORIZONTAL + 2 * DIVIDER_SPACING;
const VERTICAL_SPACE_BETWEEN_DIVIDERS = STATUS_ICON_SIZE_VERTICAL + 2 * DIVIDER_SPACING;

// The amount of overlap between a horizontal step and an adjacent divider
const HORIZONTAL_STEP_DIVIDER_OVERLAP =
    (HORIZONTAL_STEP_WIDTH - HORIZONTAL_SPACE_BETWEEN_DIVIDERS) / 2;
// The gap between steps is the length of the divider that is not overlapping with the
// adjacent steps to the left or right.
const HORIZONTAL_STEP_GAP = DIVIDER_LENGTH_HORIZONTAL - 2 * HORIZONTAL_STEP_DIVIDER_OVERLAP;

const HORIZONTAL_SPACE_BEFORE_FIRST_DIVIDER =
    HORIZONTAL_STEP_WIDTH / 2 + STATUS_ICON_SIZE_HORIZONTAL / 2 + DIVIDER_SPACING;
const VERTICAL_SPACE_BEFORE_FIRST_DIVIDER =
    STATUS_ICON_SIZE_VERTICAL + DIVIDER_SPACING + STEP_PADDING;

export function getDividerPlacementStyles(
    index: number,
    orientation: ProgressTrackerOrientation,
): CSSProperties {
    const style: CSSProperties = {};
    if (orientation === ProgressTrackerOrientation.HORIZONTAL) {
        style.left =
            index * (DIVIDER_LENGTH_HORIZONTAL + HORIZONTAL_SPACE_BETWEEN_DIVIDERS)
            + HORIZONTAL_SPACE_BEFORE_FIRST_DIVIDER;
    } else {
        style.top =
            index * (DIVIDER_LENGTH_VERTICAL + VERTICAL_SPACE_BETWEEN_DIVIDERS)
            + VERTICAL_SPACE_BEFORE_FIRST_DIVIDER;
    }
    return style;
}

/**
 * Returns the number of progress tracker items that can fit in the available space, including
 * any left or right placeholders. The minimum value is {@link MIN_NUM_ITEMS}. Only applicable
 * for progress trackers in the {@link ProgressTrackerOrientation.HORIZONTAL} orientation.
 */
export function getNumAvailableSlots(availableWidth: number): number {
    return Math.max(
        Math.floor(
            (availableWidth + HORIZONTAL_STEP_GAP) / (HORIZONTAL_STEP_WIDTH + HORIZONTAL_STEP_GAP),
        ),
        MIN_NUM_ITEMS,
    );
}

function range(start: number, length: number) {
    return [...Array(length).keys()].map((v) => start + v);
}

/**
 * Returns true if visibleWindow is a valid window given numAvailableSlots, currentStep,
 * and numTotalSteps. There can be more than one valid window.
 */
function isValidWindow(
    visibleWindow: number[],
    numAvailableSlots: number,
    currentStep: number,
    numTotalSteps: number,
): boolean {
    const firstItem = visibleWindow[0];
    const lastItem = visibleWindow[visibleWindow.length - 1];
    if (numTotalSteps <= numAvailableSlots) {
        // All steps should be visible
        return (
            visibleWindow.length === numTotalSteps
            && firstItem !== LEFT_PLACEHOLDER
            && lastItem !== RIGHT_PLACEHOLDER
        );
    }
    return (
        visibleWindow.length === numAvailableSlots
        && visibleWindow.includes(currentStep)
        && (firstItem === 0 || firstItem === LEFT_PLACEHOLDER)
        && (lastItem === numTotalSteps - 1 || lastItem === RIGHT_PLACEHOLDER)
    );
}

/**
 * Returns the new visible window of progress tracker items, including any left or right
 * placeholders. Only applicable for progress trackers in the
 * {@link ProgressTrackerOrientation.HORIZONTAL} orientation.
 *
 * Outline of behavior:
 * - If numTotalSteps >= MIN_NUM_ITEMS, then the visible window will include at least
 *   {@link MIN_NUM_ITEMS} including any placeholders, regardless of {@link numAvailableSlots}
 * - If moving to another step that is already in view, the window does not change.
 * - If moving to a later step that is not in view, that step becomes the first step after
 *   {@link LEFT_PLACEHOLDER}, unless there are not enough steps to the right for it to take
 *   that position.
 * - Likewise, if moving to an earlier step that is not in view, that step becomes the last step
 *   before {@link RIGHT_PLACEHOLDER}, unless there are not enough steps to the left for it to
 *   take that position.
 * - If {@link numAvailableSlots} increases, additional steps to the right of the current step
 *   will be shown. If all steps to the right are shown, the steps to the left will be shown.
 * - If {@link numAvailableSlots} decreases, the rightmost steps after the current step will
 *   be removed from the window. If the current step is the rightmost visible step, then the
 *   leftmost steps before the current step will be removed.
 *
 * @param visibleWindow The current visible window, including any left or right placeholders
 * @param numAvailableSlots The number of items that can fit in the progress tracker's current width
 * @param currentStep The index of the current step with regard to all steps (not just
 *      the visible window)
 * @param numTotalSteps The total number of steps
 */
export function getVisibleWindow(
    visibleWindow: number[],
    numAvailableSlots: number,
    currentStep: number,
    numTotalSteps: number,
): number[] {
    numAvailableSlots = Math.max(numAvailableSlots, MIN_NUM_ITEMS);
    if (isValidWindow(visibleWindow, numAvailableSlots, currentStep, numTotalSteps)) {
        return visibleWindow;
    } else if (numTotalSteps <= numAvailableSlots) {
        // Include all steps
        return [...Array(numTotalSteps).keys()];
    }

    // 1. Determine the minimum visible window. This includes the steps that must be included
    // in the final window, meaning these steps cannot be replaced by placeholders.
    let minimumWindow: number[];
    // The minimum number of visible steps (subtract two for left and right placeholders)
    const minVisibleSteps = numAvailableSlots - 2;
    const firstVisibleStep =
        visibleWindow[0] === LEFT_PLACEHOLDER ? visibleWindow[1] : visibleWindow[0];
    const lastVisibleStep =
        visibleWindow[visibleWindow.length - 1] === RIGHT_PLACEHOLDER
            ? visibleWindow[visibleWindow.length - 2]
            : visibleWindow[visibleWindow.length - 1];
    if (currentStep < firstVisibleStep) {
        // Moving to an earlier step that is not in view
        // Set minimumWindow so that the last visible step is the current step
        minimumWindow = range(currentStep - minVisibleSteps + 1, minVisibleSteps);
    } else if (currentStep > lastVisibleStep) {
        // Moving to a later step that is not in view
        // Set minimumWindow so that the first visible step is the current step
        minimumWindow = range(currentStep, minVisibleSteps);
    } else {
        // The current step is already in view, but numAvailableSlots has changed
        // Initially set minimumWindow to start from the first visible step of the old window
        minimumWindow = range(firstVisibleStep, minVisibleSteps);
        if (currentStep < minimumWindow[0]) {
            // If the current step is to the left of the minimum window, shift the minimum window
            // left so that first window item is the current step
            minimumWindow = minimumWindow.map((step) => step - (minimumWindow[0] - currentStep));
        } else if (currentStep > minimumWindow[minimumWindow.length - 1]) {
            // If the current step is to the right of the minimum window, shift window right so
            // that the last window item is the current step.
            minimumWindow = minimumWindow.map(
                (step) => step + (currentStep - minimumWindow[minimumWindow.length - 1]),
            );
        }
    }

    // 2. Determine the new visible window, based off minimumWindow
    let newWindow: number[] = minimumWindow;
    // Add one step to the start and end of the minimum window so that the length matches
    // numAvailableSlots
    newWindow.unshift(newWindow[0] - 1);
    newWindow.push(newWindow[newWindow.length - 1] + 1);
    const lastStep = numTotalSteps - 1;
    // If necessary, shift the window so that it doesn't stretch past the first or last steps
    if (newWindow[0] < 0) {
        // Shift window right so that first window item is the first step
        newWindow = newWindow.map((step) => step - newWindow[0]);
    } else if (newWindow[newWindow.length - 1] > lastStep) {
        // Shift window left so that the last window item is the last step
        newWindow = newWindow.map((step) => step - (newWindow[newWindow.length - 1] - lastStep));
    }
    // If necessary, replace the first and/or last steps with placeholders
    if (newWindow[0] > 0) {
        newWindow[0] = LEFT_PLACEHOLDER;
    }
    if (newWindow[newWindow.length - 1] < lastStep) {
        newWindow[newWindow.length - 1] = RIGHT_PLACEHOLDER;
    }
    return newWindow;
}

export function getStepId(prefix: string, stepIndex: number) {
    return prefix + "-step-" + stepIndex;
}
